
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { RIA } from "@Constants/Acl";
import Layout from "@Containers/LayoutV2";
import { generatePageSeo } from "next-seo.config";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import LoginComponent from "../components/NEW_TS/auth/Login";

const Login = () => {
  const { t } = useTranslation("common");
  const { locale, asPath } = useRouter();

  return (
    <Layout seoData={generatePageSeo(t("login"), locale, asPath, `${t("efreshli")} | %s `, t("about-section-summary"))}>
      <LoginComponent />
    </Layout>
  );
};

 async function _getStaticProps() {
  return {
    props: {
      ACL: {
        action: RIA
      }
    }
  };
}

export default Login;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  