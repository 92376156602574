import Link from "next/link";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";

import { useState } from "react";
import { useMedia } from "react-use";
import { useForm } from "react-hook-form";
import * as fbq from "@Services/FacebookPixel";
import { logGEvent } from "lib/gtag";

import { useAuth } from "contexts/AuthContext/Auth.context";
import { loginHandler } from "@Services/User";

import { emailPattern } from "@Constants/index";

import SocialLoginView from "../common/SocialLogin";
import Input from "@Components/NEW_TS/common/inputs/Input";
import SubmitBtn from "@Components/NEW_TS/common/SubmitBtn/SubmitBtn";

import { ForgetPasswordContainer, ForgetPasswordLink, Form, FormError, FormGroup } from "./Login.styled";
import {
  AuthWrapper,
  Banner,
  LoginKink,
  LoginLinkBtn,
  LoginLinkDesc,
  PageTitle,
  SectionContainer,
  SectionWrapper,
  Separator
} from "../common/Auth.styled";

interface LoginForm {
  email: RegExp;
  password: string;
}

const Login = () => {
  const { t } = useTranslation("login");
  const isDesktop = useMedia("(min-width: 767.98px)");
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onSubmit"
  });

  const { setUserData, updateToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const handleLogin = (formData: LoginForm) => {
    setIsLoading(true);
    setLoginError(false);
    loginHandler(formData)
      .then(({ data }) => {
        logGEvent({
          action: "click-login",
          category: "login",
          label: "login",
          value: 1
        });
        fbq.event("CompleteLogin");
        setUserData(data.data.user);
        updateToken(data.data.token);
      })
      .catch(({ response: { data } }) => {
        setIsLoading(false);
        setLoginError(data.message);
      });
  };

  return (
    <AuthWrapper>
      {isDesktop && (
        <Banner>
          <Image src="/images/auth/image.webp" alt="login" layout="fill" objectFit="cover" priority />
        </Banner>
      )}

      <SectionWrapper>
        <SectionContainer>
          <PageTitle>{t("login")}</PageTitle>
          <SocialLoginView
            setIsLoading={setIsLoading}
            setUserData={setUserData}
            updateToken={updateToken}
            actionType="login"
            fbqEvent="CompleteLogin"
          />
          <Separator>{t("or")}</Separator>
          <Form onSubmit={handleSubmit(handleLogin)} noValidate>
            <FormGroup>
              <Input
                register={register}
                name="email"
                type="email"
                label={t("email")}
                placeholder="name@domain.com"
                required={{
                  value: true,
                  errorMessage: t("required")
                }}
                pattern={{
                  value: emailPattern,
                  errorMessage: t("invalid-email")
                }}
                errors={errors?.email}
              />

              <Input
                register={register}
                name="password"
                type="password"
                label={t("password")}
                required={{
                  value: true,
                  errorMessage: t("required")
                }}
                minLength={{
                  value: 6,
                  errorMessage: t("invalid-length")
                }}
                errors={errors?.password}
              />
            </FormGroup>

            <ForgetPasswordContainer>
              <Link href="forgot-password">
                <ForgetPasswordLink type="button">{t("forget-password")}</ForgetPasswordLink>
              </Link>
            </ForgetPasswordContainer>

            <SubmitBtn isLoading={isLoading} submitText={t("submit")} />
          </Form>
          {loginError && <FormError>{loginError}</FormError>}

          <LoginKink>
            <LoginLinkDesc>{t("no-account")}</LoginLinkDesc>
            <Link href="register">
              <LoginLinkBtn>{t("sign-up")}</LoginLinkBtn>
            </Link>
          </LoginKink>
        </SectionContainer>
      </SectionWrapper>
    </AuthWrapper>
  );
};

export default Login;
